export class ShipmentsService {
  constructor(client) {
    this.client = client;
  }

  shipments(page, limit){
    return this.client.get(`/carriers/dhl/get-orders?page=${page}&limit=${limit}`);
  }

  myShipments(){
    return this.client.get('/carriers/dhl/get-my-orders');
  }

  userShipments(id, page, limit){
    return this.client.get(`/carriers/dhl/get-user-orders/${id}?page=${page}&limit=${limit}`);
  }

  trackShipment(values){
    return this.client.post('/carriers/dhl/know-tracking', values);
  }

  bookPickUpShipment(values){
    return this.client.post('/carriers/dhl/book-pickup', values);
  }
}
