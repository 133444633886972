// import axios from 'axios';
import { AuthService } from './auth.service';
import { ConfigService } from './config.service';
import { WalletService } from './wallet.service';
import { PricingService } from './pricing.service';
import { ShipmentsService } from './shipments.service';
import { UsersService } from './users.service';
import { clients } from "../utils/interceptors";

class _ApiService {
  client = clients.common;
  pricing = clients.pricing;
  wallet = clients.wallet;
  AuthService = new AuthService(this.client, this.pricing);
  WalletService = new WalletService(this.client, this.pricing, this.wallet);
  ConfigService = new ConfigService(this.client, this.pricing);
  PricingService = new PricingService(this.client, this.pricing);
  ShipmentsService = new ShipmentsService(this.client, this.pricing);
  UsersService = new UsersService(this.client, this.pricing);
  constructor(client, pricing, wallet) {
    this.client.defaults.baseURL = client;
    this.pricing.defaults.baseURL = pricing;
    this.wallet.defaults.baseURL = wallet;
  }
};

/* export const ApiService = new _ApiService('http://localhost:3000/dev'); */
export const ApiService = new _ApiService(
    'https://api-dev.shipal.co', 'https://app-princing.herokuapp.com/', 'https://app-wallet-dev.herokuapp.com/' //
);

