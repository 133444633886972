import { Button, Modal } from 'antd';
import { Formik } from 'formik';
import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { changedAmountConfig } from '../../../store/config/config.actions';
// import { getConfigAmountSelector } from '../../../store/config/config.selectors';
import { FormikInput } from '../../molecules/formik-input-field';
import { FormWrapper } from './styles';
import { schemaChardeBalance } from './validation';
import { ApiService } from '../../../services';

export const ChangeCurrencyForm = ({ balance, user }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const test1 = async (body) => {
    try {
      const { data } = await ApiService.WalletService.addBudget(body);
      setMessage(data);
      showModal()
    } catch (error) {
      console.log(error);
    }
  }; 

  return (
    <>
    <Formik
      initialValues={{
        user: user,
        balance: 0,
        type: "CRED",
        currency: "COP",
        bank: "Shipal"
      }}
      enableReinitialize
      onSubmit={(values, actions) => {
        test1(values)
        // dispatch(changedAmountConfig(values, actions.resetForm));
      }}
      validationSchema={schemaChardeBalance}
    >
      {({ isValid, handleSubmit, submitForm }) => (
        <FormWrapper onSubmit={handleSubmit}>
          <h1>Balance: {balance} COP</h1>
          <p className="text-info">Agregar saldo:</p>
          <FormikInput
            name="balance"
            style={{ width: '100%' }}
            type="number"
            placeholder="20000"
          />

          <div className="submit-button">
            <Button
              type="primary"
              ghost
              onClick={submitForm}
              disabled={!isValid}
              block
            >
              Agregar monto
            </Button>
          </div>
        </FormWrapper>
      )}
    </Formik>
    <Modal title={message.message} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
      <p>{message.bank} abono: {message.balance} para un balance total de: {message.balanceActual}{message.currency}</p>
    </Modal>
      </>
  );
};
