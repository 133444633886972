import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import {CSVLink} from "react-csv"
import { StyledTable } from './style';

export const TableCustom = ({ 
  columns,
  data = [],
  searchablePlaceholder,
  serachableObjKey,
  searchableTable = false,
  searchablePlaceholder2,
  serachableObjKey2,
  searchableTable2 = false,
  current = 0,
  handlePagination = () => alert('handle pagination'),
  total = 350,
  limit = 5,
  csvColumns = []
}) => {
  const [dataSource, setDataSource] = useState(data);
  const [value, setValue] = useState('');
  const [value2, setValue2] = useState('');

  useEffect(()=> {

  }, [data])
  const FilterByNameInput = (
    <Input
      placeholder={searchablePlaceholder}
      value={value}
      onChange={e => {
        const currValue = e.target.value;
        setValue(currValue);
        const filteredData = data.filter(entry =>
          entry[serachableObjKey].includes(currValue)
        );
        setDataSource(filteredData);
      }}
    />
  );

  const FilterByNameInput2 = (
    <Input
      placeholder={searchablePlaceholder2}
      value={value2}
      onChange={e => {
        const currValue = e.target.value;
        setValue2(currValue);
        const filteredData = data.filter(entry =>
          entry[serachableObjKey2].includes(currValue)
        );
        setDataSource(filteredData);
      }}
    />
  );

  var columns2 = [];
  searchableTable && columns2.push({
    title: FilterByNameInput,
    dataIndex: serachableObjKey,
    key: '1'
  })
  searchableTable2 && columns2.push({
      title: FilterByNameInput2,
      dataIndex: serachableObjKey2,
      key: '2'
  })
  columns2 = [...columns2, ...columns];

  const dataSorter = columns2.map((item) => {
    const sorter = (a, b) => a[item.dataIndex] - b[item.dataIndex];
    return {
      sorter,
      ...item,
    };
  });
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };
  const reRender = () => {
    setDataSource(data)
  }

  return (
    <>
    <StyledTable
      columns={dataSorter}
      dataSource={data}
      onChange={onChange} 
      pagination={{
        showSizeChanger: false,
        current, 
        onChange: (pagination) => handlePagination(pagination),
        total,
        pageSize: limit
      }}
    />
    {csvColumns.length !== 0 && (
      <CSVLink
        filename={"TablaShipal.csv"}
        data={dataSource.map(item => csvColumns.reduce((acc, key) => ({...acc, [key]: item[key]}), {}))}
        className="btn btn-primary"
      >
        Descargar información
      </CSVLink>
    )}
    </>);
};
