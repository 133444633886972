const config = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}
export class PricingService {
  constructor(client, pricing) {
    this.client = client;
    this.pricing = pricing;
  }
  getLastCurrency() {
    return this.pricing.get('/pricing/find-last-currency');
  };

  putNewCurrency(id) {
    return this.pricing.put(`/pricing/currency?currency=${id}`);
  };

  uploadExcel(body){
    var formData = new FormData();
    formData.append("file", body.file);
    formData.append("national", body.national);
    console.log(body);
    return this.pricing.post(`/pricing/upload`, formData, config);
  }

  uploadNationalZones(body){
    var formData = new FormData();
    formData.append("file-zones", body.file);
    return this.pricing.post('/pricing/upload-national-zones', formData, config)
  }

  uploadZones(body){
    var formData = new FormData();
    formData.append("file-zones", body.file);
    formData.append("file-countries", body.file2);
    return this.pricing.post('/pricing/upload-zones', formData, config)
  }
}
