import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { Spin } from 'antd'
import { useDispatch/* , useSelector */ } from 'react-redux';
import {
  getUsersAuthDataAction,
} from '../../../store/auth1/auth.actions';
import { Space, Button  } from 'antd';
import { TableCustom } from '../../organims/table';
import { ModalBalance } from '../../organims/modal-currency';
import { ApiService } from '../../../services';
import { useHistory } from 'react-router';
import { PageTitle } from '../../molecules/page-title';

export const UsersPage = () => {
  const [ envios, setEnvios ] = useState();
  const [ page, setPage ] = useState(1);
  const [ limit, setLimit ] = useState(50);
  const [ totalDocs, setTotalDocs ] = useState(5);
  const dispatch = useDispatch();
  const history = useHistory();
  const balanceRef = useRef(null);
  const handlePagination = (pagination) => {
    console.log('pagination',pagination);
    test(pagination);
  }
  const handleActivateUser = async (user) => {
    try {
      const { data } = await ApiService.AuthService.updateUser(user._id, {isActive: !user.isActive});
      console.log(data);
      if (data.statusCode === 200){
        test()
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    dispatch(getUsersAuthDataAction());
    test(page)
  }, []);
  const test = async (pageIndex) => {
    try {
      const { data } = await ApiService.UsersService.getUsers(pageIndex, limit);
      console.log('user data: ', data, data.data.page, data.data.totalPages);
      setPage(data.data.page);
      setTotalDocs(data.data.totalDocs);
      setEnvios(data.data.docs.map((user, index) => ({
        name: user.firstName,
        envios: `${Math.random().toFixed(2) * 100}-(Q)`,
        startDate: new Date(user.createdAt).toLocaleString(),
        value: `${user.balanceAvailable} COP`,
        key: user.id,
        user
      })));
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <a>{text}</a>,
    },/* 
    {
      title: 'Envíos',
      dataIndex: 'envios',
      key: 'envios',
    }, */
    {
      title: 'Fecha registro',
      dataIndex: 'startDate',
      key: 'address',
    },
    {
      title: 'Balance billetera',
      dataIndex: 'value',
      key: 'age',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button
            onClick={() => history.push({ 
              pathname: '/users-detail',
              search: `?userinfo=${record.name}`,
              state: record.user
            })}
          >
            Info: {record.name}
          </Button>
          <Button
            onClick={() => handleActivateUser(record.user)}
          >
            {record.user.isActive ? 'Desactivar' : 'Activar'}
          </Button>
        </Space>
      ),
    },
  ];
  if(!envios) return <div style={{ margin: 'auto 50%'}}><Spin size="Large" /></div>;
  return (
    <div>
      <PageTitle  title="Usuarios" />
      <p>En el módulo de usuarios podremos administrar y analizar los usuarios de la plataforma...</p>
      <TableCustom
        columns={columns}
        data={envios}
        current={page}
        handlePagination={handlePagination}
        total={totalDocs}
        limit={limit}
      />
      <ModalBalance ref={balanceRef} />
    </div>
  );
};

